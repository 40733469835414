
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import supplier from '@/services/supplier';
import { SupplierType } from '@/components/types/enums';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import { store } from '@/store';
import { LayoutRequests } from '@/models/CertificateModel';
import { DevelopmentSheetMutationTypes } from '@/store/modules/development-sheet/mutation-types';

export default defineComponent({
  name: 'SelectFieldPricesDistributorModal',
  emits: ['closeModal', 'changeSuppliers'],
  components: { BaseCheckbox, ModalWrapper },
  data: () => ({
    suppliers: [] as any,
    isOnlyFocusedBrands: false
  }),
  computed: {
    onlyFocusedBrands(): boolean {
      return this.$store.getters.onlyFocusedBrands
    },
    isDisabledBtn(): any {
      return !this.suppliers.some((supplier: any) => supplier.visible)
    }
  },
  methods: {
    toggleOnlyFocusedBrands() {
      this.isOnlyFocusedBrands = !this.isOnlyFocusedBrands
    },
    setChecked(checkboxIndex: number) {
      this.suppliers[checkboxIndex].visible = !this.suppliers[checkboxIndex].visible;
    },
    async save() {
      this.$store.commit(DevelopmentSheetMutationTypes.TOGGLE_ONLY_FOCUSED_BRANDS, this.isOnlyFocusedBrands)

      try {
        this.suppliers.map(async (currentSupplier: any) => {
          await supplier.setVisible(
            currentSupplier.id,
            currentSupplier.visible
          )
        })
        this.$emit('closeModal')
        this.$emit('changeSuppliers', this.suppliers)
      } catch (error) {
        this.$emit('closeModal')
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
      }
    },
  },
  async mounted() {
    this.suppliers = await supplier.getAllByType(SupplierType.DISTRIBUTOR)

    // this.suppliers = suppliers.filter((sup: any) => {
    //   return !['TECDOC', 'ABCP', 'AUTOEURO'].includes(sup.name)
    // })
  }
})
