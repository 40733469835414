
import {defineComponent} from 'vue';
import SearchDropdown from "@/components/search/SearchDropdown.vue";
import BaseIcon from "@/components/ui/BaseIcon.vue";
import BaseError from "@/components/ui/BaseError.vue";
import PriceListModal from "@/components/modals/PriceListModal.vue";
import MasteringSheet from "@/services/MasteringSheet";
import DescTable from "./DescTable.vue"
import PricesTable from "./PricesTable.vue"
import TurnoversTable from "./TurnoversTable.vue"
import SettingTable from './SettingTable.vue';
import Spinner from '@/components/Spinner.vue';
import router from "@/router";
import prices from '@/services/prices'
import articles from "@/services/articles";
import timeService from "@/services/timeService";
import supplier from '@/services/supplier';
import SelectFieldPricesDistributorModal from '@/components/modals/SelectFieldPricesDistributorModal.vue';
import { SupplierType } from '@/components/types/enums';
import { DevelopmentSheetMutationTypes } from '@/store/modules/development-sheet/mutation-types';
import { SocketSubscribeOutputBody } from '@/plugins/SocketManager';

  export default defineComponent({
    name: 'PricePage',
    components: {
      SelectFieldPricesDistributorModal,
      SearchDropdown,
      BaseIcon,
      BaseError,
      DescTable,
      PricesTable,
      TurnoversTable,
      Spinner,
      PriceListModal,
      SettingTable
    },
    emits: ['setSort'],
    data:() => ({
      pageable: null as any,
      activeMenu: 'desc' as string,
      isLoading: true,
      percentFileLoading: 0,
      isFileLoading: false,
      menu: [
        {key: 'desc', value: 'Описание'},
        {key: 'prices', value: 'Прайсы'},
        {key: 'turnovers', value: 'В наличии'},
        {key: 'setting', value: 'Настройка файлов'}
      ] as object,
      priceInfo: {} as any,
      tablePrice: [] as any,
      showError: false,
      sort: 'none' as string,
      viewedSheet: {} as any,
      isDropdownRevealed: false as boolean,
      priceFocus: 'all' as string,
      suppliers: [] as any,
    }),
    computed: {
      checkUserRole(): any {
        return this.$store.getters.getAllPermissions.prices_action[this.$store.getters.getUserRole]
      },
      checkUserCanDownloadExcel(): boolean {
        return this.$store.getters.getAllPermissions.prices_to_excel[this.$store.getters.getUserRole]
      },
      // eslint-disable-next-line consistent-return
      pricesListName(): any {
        if (this.$route.name === 'price_sheet') {
          return this.viewedSheet.name;
        } else if (this.$route.name === 'price_list') {
          return 'Лист проценки';
        } else if (this.$route.name === 'price_article') {
          return this.priceInfo.content[0].article.article
        }
      },
      isPriceInfo(): any {
        return this.priceInfo.content && this.priceInfo.content.length && this.priceInfo.content.some((content: any) => content.crossPrices.some((cp: any) => cp.prices.length))
      },
      isUserCanChangeViewSuppliers(): boolean {
        return this.$store.getters.getAllPermissions.view_suppliers_on_prices_analyses[this.$store.getters.getUserRole]
      }
    },
    watch: {
      '$route.path': function(val) {
        this.setDefaultData()
      },
    },
    methods: {
      async getPricesBySheet(page: number) {
        const sheet = this.$route.params.id;

        this.viewedSheet = await MasteringSheet.getSheetById(Number(sheet));

        this.priceInfo = await prices.getPricesForMasteringSheet(Number(sheet), page);

        this.pageable = {
          pageNumber: this.priceInfo.pageable.pageNumber,
          totalPages: this.priceInfo.totalPages,
        }

        this.isLoading = false;
        if (this.priceInfo && this.priceInfo.content && this.priceInfo.content.length) {
          this.convertToTableData(this.priceInfo.content)
        } else {
          this.showError = true
        }
      },
      async getPricesByArticle(page: number) {
        this.isLoading = true
        const articleIds = String(this.$route.params.ids).split('&');
        this.priceInfo = await prices.getPricesForArticles({articleIds: articleIds}, page || 0);

        this.pageable = {
          pageNumber: this.priceInfo.pageable.pageNumber,
          totalPages: this.priceInfo.totalPages,
        }

        this.isLoading = false
        if (this.priceInfo && this.priceInfo.content && this.priceInfo.content.length) {
          this.convertToTableData(this.priceInfo.content)
        } else {
          this.showError = true
        }
        this.isLoading = false
      },
      previous() {
        if (!!this.pageable.pageNumber) {
          const prevPage = this.pageable.pageNumber - 1
          if (this.$route.name === 'price_sheet') {
            this.getPricesBySheet(prevPage)
          } else {
            this.getPricesByArticle(prevPage)
          }
        }
      },
      next() {
        if ((this.pageable.pageNumber + 1) !== this.pageable.totalPages) {
          const nextPage = this.pageable.pageNumber + 1

          if (this.$route.name === 'price_sheet') {
            this.getPricesBySheet(nextPage)
          } else {
            this.getPricesByArticle(nextPage)
          }
        }
      },
      goToBack() {
        router.push('/prices')
      },
      goToPrevious() {
        router.go(-1)
      },
      setActiveMenu(item: any): any {
        this.activeMenu = item.key
      },
      convertToTableData(items: any): any {
        this.tablePrice = [];
        items.forEach((item: any) => {
          let tablePriceItem = {
            article: item.article.article,
            articleFull: item.article,
            prodGroup: item.article.productGroup.desc,
            comment: `${item.article.commentPrice ? ', ' + item.article.commentPrice : ''}`,
            application: item.article.commentApplic,
            focusOE: item.crossPrices.map((cp: any) => cp.cross.brand + ' ' + cp.cross.brandArt).join('<br>'),
            brands: item.crossPrices.filter((cp: any) => cp.prices.length).map((cp: any) => cp.cross.brand).join('<br>'),
            brandArticles: item.crossPrices.filter((cp: any) => cp.prices.length).map((cp: any) => cp.cross.brandArt).join('<br>'),
            // brands_articles: item.crossPrices.map((cp: any) => cp.cross.brand + ' ' + cp.cross.brandArt).join('<br>'),
            brandDesc: item.crossPrices.filter((cp: any) => cp.prices.length).map((cp: any) => `<b style="font-weight: 600;">${cp.cross.brand} ${cp.cross.brandArt}</b><br>${cp.prices.map((p: any) => `${p.description}`)}`).join('<br><br>'),
            brandPrices: item.crossPrices.filter((cp: any) => cp.prices.length).map((cp: any) => {
              let prices = cp.prices.map((p: any) => p.price);
              return `${Math.min.apply(null, prices)}`
            }).join('<br>'),
            prices: item.crossPrices.filter((cp: any) => cp.prices.length).length
              ? this.getOwnPrices(item).concat(item.crossPrices.filter((cp: any) => cp.prices.length))
              : [],
          };
          this.tablePrice.push(tablePriceItem)
        })
      },
      getOwnPrices(item: any) {
        let cross = {
          brand: 'FENOX',
          brandArt: item.article.article,
          description: item.articlePrices.map((ap: any) => ap.description).join(', '),
        };

        let res = {
          cross: cross,
          prices: item.articlePrices,
          rrc: item.rrc
        };
        return [res]
      },
      setViewedArticles(item: { [x: string]: any }) {
        let result: { [x: string]: any } = [];
        let storage = JSON.parse(localStorage.getItem('VIEWED_ARTICLES')!);
        if (storage) result = result.concat(storage);
        let found = result.find((o: any) => o.articleId === item.articleId);
        if (found) {
          result.splice(result.indexOf(result.find((o: any) => o.articleId === item.articleId)), 1)
          result.unshift(item)
        }
        if (!found) result.unshift(item);
        if (result.length > 6) result.length = 6;
        localStorage.setItem('VIEWED_ARTICLES', JSON.stringify(result))
      },
      async downloadTable() {
        this.$socketService.connect(() => {
          this.$socketService.subscribe('/user/notifications', (message) => {
            const output = JSON.parse(message.body)
            if (output.resultAsExcel) {
              let byteTable = atob(output.resultAsExcel);
              let n = byteTable.length;
              let u8arr = new Uint8Array(n);
              while(n--){
                u8arr[n] = byteTable.charCodeAt(n);
              }
              let date = timeService.createDateForViewFromUTCTime();
              const blob = new Blob([u8arr], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              });
              const link = document.createElement('a');
              const url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', `Price_report_${this.pricesListName}_${date}.xls`);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            } else if (output.resultAsPath) {
              const href = `${process.env.VUE_APP_URL_FILES}${output.resultAsPath.split('\\').join('/')}`
              const link = document.createElement('a');
              link.setAttribute('href', href);
              link.setAttribute('target', '_blank');
              document.body.appendChild(link);
              link.click();
              link.remove();
            }
            else if (output.progressInfo) {
              if (output.progressInfo.percent >= 100) {
                this.percentFileLoading = 0
                this.isFileLoading = false
              } else {
                this.percentFileLoading = output.progressInfo.percent
              }
            }
          })
        })


        this.isFileLoading = true
        if (this.$route.name === 'price_article') {
          const article = this.$route.params.id;
          await prices.exportPricesForArticles({ articleIds: [article] });
        } else if (this.$route.name === 'price_sheet'){
          const sheet = this.$route.params.id;
          await prices.exportPricesForMasteringSheet(Number(sheet), []);
        } else if (this.$route.name === 'price_list') {
          this.isFileLoading = true
          const articleIds = String(this.$route.params.ids).split('&');
          await prices.exportPricesForArticles({articleIds: articleIds});
        }
      },

      openPriceListModal() {
        let element = document.getElementById(`modal-price-list`)!
        element.style.display = 'flex'
      },
      setSort() {
        this.sort = this.sort === 'asc' ? 'desc' : 'asc';
        console.log('This.sort', this.sort)
        if (this.priceInfo && this.priceInfo.content && this.priceInfo.content.length) {
          this.priceInfo.content.forEach((content: any) => {
            if (this.sort === 'asc') content.crossPrices = content.crossPrices.sort(this.sortAscByPrice);
            if (this.sort === 'desc') content.crossPrices = content.crossPrices.sort(this.sortAscByPrice).reverse()
          });

          this.convertToTableData(this.priceInfo.content)
        }
      },
      sortAscByPrice(a: any, b: any) {
        const minPriceA = Math.min(...a.prices.map((p: any) => p.price));
        const minPriceB = Math.min(...b.prices.map((p: any) => p.price));
        return minPriceA - minPriceB;
      },
      async setDefaultData() {
        this.isLoading = true;
        this.viewedSheet = {};
        if (this.$route.name === 'price_article') {
          const article = this.$route.params.id;

          const result = await articles.getArticleById(Number(article));
          this.setViewedArticles(result);

          this.priceInfo = await prices.getPricesForArticles({articleIds: [article]});
          if (this.priceInfo && this.priceInfo.content && this.priceInfo.content.length) {
            this.convertToTableData(this.priceInfo.content)
          } else {
            this.showError = true
          }
        } else if (this.$route.name === 'price_sheet') {
          await this.getPricesBySheet(0)
        } else if (this.$route.name === 'price_list') {
          await this.getPricesByArticle(0)
        }

        this.isLoading = false
      },
      async changeSuppliers(currentSuppliers: any) {
        await this.getAllSuppliers()
        this.suppliers = this.suppliers.filter((supplier: any) => {
          const foundedSupplier = currentSuppliers.find((currentSupplier: any) => {
            return currentSupplier.id === supplier.id
          })

          return foundedSupplier.visible
        })

        await this.setDefaultData()
      },
      async getAllSuppliers() {
        this.suppliers = await supplier.getAllByType(SupplierType.DISTRIBUTOR)
        // this.suppliers = suppliers.filter((sup: any) => {
        //   return !['TECDOC', 'ABCP', 'AUTOEURO'].includes(sup.name)
        // })
      },
      openModalSelectField() {
        if (this.isUserCanChangeViewSuppliers) {
          let element = document.getElementById(`modal-select-field-prices-distributor`)!;
          element.style.display = 'flex'
        }
      },
      closeModalSelectField() {
        let element = document.getElementById(`modal-select-field-prices-distributor`)!;
        element.style.display = 'none'
      }
    },
    async mounted() {
      await this.getAllSuppliers()
      await this.setDefaultData()
    },
    unmounted() {
      this.$store.commit(DevelopmentSheetMutationTypes.TOGGLE_ONLY_FOCUSED_BRANDS, false)
    }
  })
